import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Banner from "../containers/areas/banner";
import Area from "../containers/areas/area";

export default () => (
  <Layout headerLayout={2}>
    <SEO title="Area" />
    <Banner />
    <Area />
  </Layout>
);
